var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "px-0 py-0" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "pb-0" }, [
            _c(
              "p",
              {
                staticClass: "pl-5 mt-3 mb-0 font-weight-black",
                staticStyle: { "font-size": "20px" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("label.agencyConnectionList")) +
                    "\n      "
                )
              ]
            )
          ]),
          _c(
            "v-col",
            { staticClass: "pb-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-black float-right",
                  staticStyle: { "font-size": "20px" },
                  attrs: {
                    slot: "activator",
                    align: "center",
                    width: "70vw",
                    "max-width": "250px",
                    color: "next",
                    dark: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.onClickRegisterAgencyConnectionButton()
                    }
                  },
                  slot: "activator"
                },
                [_vm._v(_vm._s(_vm.$t("button.agencyConnectionRegister")))]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-black float-right",
                  staticStyle: { "font-size": "20px" },
                  attrs: {
                    slot: "activator",
                    align: "center",
                    width: "70vw",
                    "max-width": "250px",
                    color: "next",
                    dark: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.onClickDeleteAgencyConnectionButton()
                    }
                  },
                  slot: "activator"
                },
                [_vm._v(_vm._s(_vm.$t("button.agencyConnectionDelete")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-actions",
                    [
                      _c("AgencySearchList", {
                        attrs: {
                          records: _vm.agencyRecords,
                          checkboxes: _vm.agencyCheckboxes,
                          subheader1: _vm.$t("label.subheader.agencyCode"),
                          subheader2: _vm.$t("label.subheader.agencyName"),
                          subheader3: _vm.$t("label.subheader.salesCode")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("AgencyConnectionSearchDialog", {
        ref: "agencyConnectionSearchDialog",
        on: { onSuccess: _vm.onSuccessSearchAgency }
      }),
      _c("AgencyConnectionUpdateConfirmDialog", {
        ref: "agencyConnectionRegisterConfirmDialog",
        attrs: { isRegister: true },
        on: { onSuccess: _vm.onSuccessRegisterAgencyConnection }
      }),
      _c("AgencyConnectionUpdateConfirmDialog", {
        ref: "agencyConnectionDeleteConfirmDialog",
        attrs: { isRegister: false },
        on: { onSuccess: _vm.onSuccessDeleteAgencyConnection }
      }),
      _c("CompletedDialog", { ref: "completedDialog" }),
      _c("ErrorDialog", { ref: "errorDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }